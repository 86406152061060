import { CCard, CCardGroup } from "@coreui/react";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getCategories,
  getProducts,
  getSizes,
  getColor,
  getMaterial,
  uploadProductImage,
  addProduct,
  updateTemplate,
} from "../../actions/pdfManagement";
import { bindActionCreators } from "redux";
import addSymbol from "../../public/images/addSymbol.svg";
import backArrow from "../../public/images/backArrow.svg";
import { useHistory } from "react-router-dom";
import {
  addTemplate,
  getadditionalPartTypes,
} from "../../actions/manageTemplate";
import { NumericFormat } from "react-number-format";
import CustomModal from "../../components/shared/CustomModal";
import SaveTemplate from "./SaveTemplate";
import plusIcon from "../../public/images/plusIcon.svg";
import crossIcon from "../../public/images/crossIcon.svg";

const AddNewLineItem = (props) => {
  const [totalPrice, setTotalPrice] = useState(122);
  const [key, setKey] = useState(Math.random());
  const [titleError, setTitleError] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState([]);
  const [getCategory, setGetCategory] = useState("");
  const [productType, setProductType] = useState([]);
  const [getProduct, setGetProduct] = useState("");
  const [sizes, setSizes] = useState([]);
  const [color, setColor] = useState([]);
  const [material, setMaterial] = useState([]);
  // const [categorySlug, setCatego] = useState()
  const [loading, setLoading] = useState(false);
  const [getPart, setGetPart] = useState([]);

  const history = useHistory();
  const toastId = React.useRef(null);

  const [values, setValues] = useState([""]);

  const [forms, setForms] = useState([
    {
      categoryId: "",
      categoryName: "",
      categorySlug: "",
      categoryType: "",
      subCategoryId: "",
      subCategoryName: "",
      subCategorySlug: "",
      colorId: "",
      colorName: "",
      sizeId: "",
      sizeName: "",
      materialId: "",
      materialName: "",
      showParts: false,
      sizeId: "",
      description: "",
      mitreStyles: [],
      hangerTypes: [""],
      elbowType: {
        a: 0,
        b: 0,
        c: 0,
      },
      unitPrice: "0.00",
      quantity: "",
      totalPrice: "0.00",
      photos: [],
      additionalParts: [],
    },
  ]);

  const handleAddForm = (e) => {
    const isValid = validateForm();
    e.preventDefault();
    const newForm = {
      categoryId: "",
      categoryName: "",
      categorySlug: "",
      categoryType: "",
      subCategoryId: "",
      subCategoryName: "",
      subCategorySlug: "",
      colorId: "",
      colorName: "",
      materialId: "",
      materialName: "",
      showParts: false,
      sizeId: "",
      mitreStyles: [],
      hangerTypes: [],
      elbowType: {
        a: 0,
        b: 0,
        c: 0,
      },
      description: "",
      unitPrice: "0.00",
      quantity: "",
      totalPrice: "0.00",
      photos: [],
      additionalParts: [],
    };

    const newFormErrors = {
      categoryId: "",
      categoryName: "",
      categorySlug: "",
      categoryType: "",
      subCategoryId: "",
      subCategoryName: "",
      subCategorySlug: "",
      colorId: "",
      colorName: "",
      materialId: "",
      materialName: "",
      showParts: false,
      sizeId: "",
      mitreStyles: [],
      hangerTypes: [],
      elbowType: {
        a: 0,
        b: 0,
        c: 0,
      },
      description: "",
      unitPrice: "",
      quantity: "",
      totalPrice: "",
      photos: [],
      additionalParts: [],
    };

    if (isValid) {
      setForms((prevForms) => [...prevForms, newForm]);

      setFormErrors((prevErorrs) => [...prevErorrs, newFormErrors]);
    }
  };

  const handleAddMitre = (e, index) => {
    e.preventDefault();
    const firstHangerType = forms[index].hangerTypes[0];
    const lastHangerType =
      forms[index].hangerTypes[forms[index].hangerTypes.length - 1];

    if (firstHangerType === "") {
      toast.error("Please fill in  hanger details.");
      return;
    }

    if (
      lastHangerType.hangerId === "" ||
      lastHangerType.hangerName === "" ||
      lastHangerType.hangerCount === ""
    ) {
      toast.error("Please fill in hanger details.");
      return;
    }

    const newHangerTypes = [
      ...forms[index].hangerTypes,
      { hangerId: "", hangerCount: "", hangerName: "" },
    ];
    const newForms = [...forms];
    newForms[index].hangerTypes = newHangerTypes;
    setForms(newForms);
  };

  const handleRemoveMitre = (index, indexTwo) => {
    const newHangerTypes = forms[index].hangerTypes.filter(
      (item, i) => i !== indexTwo
    );
    const newForms = [...forms];
    newForms[index].hangerTypes = newHangerTypes;
    setForms(newForms);
  };

  const handleInputChange = (event, index, type = null, fieldName = null) => {
    const { name, value, files } = event.target;

    if (name == "categoryId") {
      setGetCategory(value);
      const foundCategory = category.find(({ _id }) => {
        return _id === value;
      });

      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[index][name] = value;
        updatedForms[index].categoryName = foundCategory.name;
        updatedForms[index].categorySlug = foundCategory.slug;
        updatedForms[index].categoryType = foundCategory.type;
        return updatedForms;
      });
      handleCategory(value, index);
    } else if (name == "subCategoryId") {
      const foundSubCatgeory = productType.find(({ _id }) => {
        return _id === value;
      });

      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[index][name] = value;
        updatedForms[index].subCategoryName = foundSubCatgeory.name;
        updatedForms[index].subCategorySlug = foundSubCatgeory.slug;
        return updatedForms;
      });
      handleProduct(value, index);
    } else if (name == "unitPrice") {
      const newValue = value.replace(/\$/g, "");

      const unitPrice = newValue !== "" ? newValue : "0"; // Set unitPrice to "0" if empty
      const quantity = forms[index].quantity ? forms[index].quantity : "1";

      forms.forEach((data, index) => {
        if (data.categorySlug === "gutters" || data.categorySlug === "downspouts" || data.categorySlug === "gutter_covers") {
          setForms((prevForms) => {
            const updatedForms = [...prevForms];
            updatedForms[index].unitPrice = newValue;

            const totalAmount = parseFloat(unitPrice) * parseInt(quantity);

            function formatNumber(number) {
              const formattedNumber = new Number(number).toFixed(2);
              const decimalPart = formattedNumber.split('.')[1];

              if (decimalPart === "00" || decimalPart === "0") {
                return formattedNumber?.replace(/\.00$/, '');
              } else if (decimalPart?.charAt(1) === "0") {
                return formattedNumber?.slice(0, -1); // Remove one trailing zero
              } else {
                return formattedNumber;
              }
            }

            // Set totalPrice based on the unitPrice value
            updatedForms[index].totalPrice =
              unitPrice !== "0" ? formatNumber(totalAmount) : "0.00";

            return updatedForms;
          });
        } else {
          setForms((prevForms) => {
            const updatedForms = [...prevForms];
            updatedForms[index].unitPrice = newValue;

            const totalAmount = parseInt(unitPrice);

            function formatNumber(number) {
              const formattedNumber = new Number(number).toFixed(2);
              const decimalPart = formattedNumber.split('.')[1];

              if (decimalPart === "00" || decimalPart === "0") {
                return formattedNumber?.replace(/\.00$/, '');
              } else if (decimalPart?.charAt(1) === "0") {
                return formattedNumber?.slice(0, -1); // Remove one trailing zero
              } else {
                return formattedNumber;
              }
            }

            // Set totalPrice based on the unitPrice value
            updatedForms[index].totalPrice =
              unitPrice !== "0" ? formatNumber(totalAmount) : "0.00";

            return updatedForms;
          });
        }
      });
    } else if (name == "quantity") {
      const newValue = value.replace(/\$/g, "");
      const unitPrice =
        forms[index].unitPrice !== "" ? forms[index].unitPrice : "1"; // Set unitPrice to "0" if empty
      const quantity = newValue ? newValue : "1";

      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[index][name] = parseInt(value);
        return updatedForms;
      });

      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        const totalAmount = parseInt(unitPrice) * parseInt(quantity);
        updatedForms[index].totalPrice = totalAmount.toFixed(2) + "";
        return updatedForms;
      });
    } else if (name == "colorId") {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        const selectedOption = event.target.options[event.target.selectedIndex];
        const innerText = selectedOption.innerText;
        updatedForms[index].colorId = value;
        updatedForms[index].colorName = innerText;
        return updatedForms;
      });
    } else if (name == "materialId") {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        const selectedOption = event.target.options[event.target.selectedIndex];
        const innerText = selectedOption.innerText;
        updatedForms[index].materialId = value;
        updatedForms[index].materialName = innerText;
        return updatedForms;
      });
    } else if (name == "sizeId") {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        const selectedOption = event.target.options[event.target.selectedIndex];
        const innerText = selectedOption.innerText;
        updatedForms[index].sizeId = value;
        updatedForms[index].sizeName = innerText;
        return updatedForms;
      });
    } else if (name === "description") {

      setForms((prevForms) => {
        const updatedForms = [...prevForms];

        updatedForms[index][name] = value;
        return updatedForms;
      });
    }
    else {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];

        updatedForms[index][name] = value;
        return updatedForms;
      });
    }
  };

  const handleCategory = (e, index) => {
    if (e !== "") {
      getAllColors(e, index);
      getAllMaterial(e, index);
      getAllProducts(e, index);
      getAdditionalPartTypes(e, index);
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[index]["showParts"] = true;
        return updatedForms;
      });
    } else if (e === "") {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[index]["showParts"] = false;
        return updatedForms;
      });
    }
  };

  const handleProduct = (e, index) => {
    setGetProduct(e);
    if (e !== "") {
      getAllSizes(e);
    }
  };

  const getAllSizes = (id) => {
    let params = {
      categoryId: id,
    };
    props.getSizes(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          setSizes(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const getAllCategories = () => {
    let params = {
      pageType: "quote",
    };
    props.getCategories(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          setCategory(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  console.log(props.jobTitle, "jobTitle ");
  const getAllProducts = (id) => {
    let params = {
      categoryId: id,
    };
    props.getProducts(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          setProductType(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const [formErrors, setFormErrors] = useState([
    {
      categoryId: "",
      categoryName: "",
      categorySlug: "",
      categoryType: "",
      subCategoryId: "",
      subCategoryName: "",
      subCategorySlug: "",
      colorId: "",
      colorName: "",
      sizeId: "",
      sizeName: "",
      materialId: "",
      materialName: "",
      showParts: false,
      sizeId: "",
      description: "",
      mitreStyles: [],
      hangerTypes: [],
      elbowType: {
        a: 0,
        b: 0,
        c: 0,
      },
      unitPrice: "",
      quantity: "",
      totalPrice: "",
      photos: [],
      additionalParts: [],
    },
  ]);

  const validateForm = () => {
    let hasError = false;
    // Clear previous errors
    setFormErrors([...forms.map(() => ({}))]);
    forms.forEach((data, index) => {
      let errors = {};
      if (data.categoryName === "") {
        errors.categoryName = "Please choose a category.";
        toast.error(errors.categoryName);
      } else if (data.description === "" || data.description.trim() === '') {
        errors.description = "Please enter the description of the template.";
        toast.error(errors.description);
      } else if (data.photos.length <= 0) {
        errors.photos = "Please upload photos of the template.";
        toast.error(errors.photos);
      }
      /*  else if (
        data.categorySlug === "gutters" ||
        data.categorySlug === "downspouts" ||
        data.categorySlug === "gutter_covers"
      ) {
        if (data.subCategoryName === "") {
          errors.subCategoryName = "Please choose a product type.";
          toast.error(errors.subCategoryName);
        } else if (data.sizeName === "") {
          errors.sizeName = "Please choose a product size.";
          toast.error(errors.sizeName);
        } else if (data.unitPrice === "" || data.unitPrice === "0" || data.unitPrice === "0.0" || data.unitPrice === "0.00") {
          errors.unitPrice = "Please enter the unit price.";
          toast.error(errors.unitPrice);
        } else if (data.quantity === "") {
          errors.quantity = "Please enter the quantity.";
          toast.error(errors.quantity);
        }
      } else if (data.unitPrice === "" || data.unitPrice === "0" || data.unitPrice === "0.0" || data.unitPrice === "0.00") {
        errors.unitPrice = "Please enter the unit price ";
        toast.error(errors.unitPrice);
      } */
      if (Object.keys(errors).length > 0) {
        hasError = true;
        setFormErrors((prevErrors) => [
          ...prevErrors.slice(0, index),
          errors,
          ...prevErrors.slice(index + 1),
        ]);
      }
    });

    return !hasError;
  };

  const addTemplate = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      console.log("function calledddddddddddd");

      const updatedData = forms.concat(props.oldData);

      const updatedNewObj = updatedData.map((item) => {
        if (item.mitreStyles) {
          item.mitreStyles = item.mitreStyles.filter((mitre) => mitre !== null);
          item.mitreStyles = item.mitreStyles.map(({ _id, ...item }) => item)
        }

        if (item.hangerTypes && item.hangerTypes[0] === "") {
          item.hangerTypes = [];
        }
        if (item.hangerTypes) {
          item.hangerTypes = item.hangerTypes.map(({ _id, ...item }) => item)
        }
        if (item.quantity === "" || item.quantity === null) {
          item.quantity = "";
        }
        return item;
      });

      const postData = updatedNewObj.map(
        ({
          subCategoryId,
          sizeId,
          materialId,
          showParts,
          additionalParts,
          _id,
          ...rest
        }) => rest
      );

      console.log(postData, "psot dataaaa");

      const cleanedJobDetails = postData.map((job) => {
        // Check if any field is empty or contains null
        const cleanedJob = {};
        for (const key in job) {
          if (job.hasOwnProperty(key)) {
            const value = job[key];
            if (value !== null && value !== "") {
              cleanedJob[key] = value;
            }
          }
        }
        return cleanedJob;
      });

      console.log(cleanedJobDetails, "cleaned Job detaisl");

      let params = {
        templateId: props.templateId,
        title: props.title,
        jobTitle: props.jobTitle,
        jobDetails: cleanedJobDetails,
        token: props.auth.token,
      };

      props.updateTemplate(
        params,
        (res) => {
          setLoading(false);
          if (res.status) {
            console.log("Updated ------------------------");
            // props.show("")
            history.push("/manage-template");
          } else {
          }
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong!");
        }
      );
    }
  };

  const getAllColors = (id) => {
    let params = {
      categoryId: id,
    };
    props.getColor(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          setColor(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const getAllMaterial = (id) => {
    let params = {
      categoryId: id,
    };
    props.getMaterial(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          setMaterial(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const onUploadProductImage = (e, index, fieldName) => {
    e.preventDefault();
    const files = e.target.files;

    const allowedFormats = ["jpg", "jpeg", "png"]; // Add more allowed formats if needed

    const allFilesAreImages = Array.from(files).every((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return allowedFormats.includes(fileExtension);
    });

    if (!allFilesAreImages) {
      toast.error("Please select only image files (jpg, jpeg, png).");
      return;
    }
    const { name } = e.target;
    if (fieldName === "thumbnail") {
      const imageFiles = e.target.files;
      if (forms[index].photos.length + imageFiles.length > 8) {
        toast.error("You can't upload more than 8 images.");
        return;
      }

      let params = {
        file: imageFiles,
      };
      props.uploadProductImage(
        params,
        (res) => {
          setLoading(false);
          if (res.status) {
            setForms((prevForms) => {
              const updatedForms = [...prevForms];
              const newImages = updatedForms[index].photos;
              updatedForms[index].photos = [
                ...updatedForms[index].photos,
                ...res.data.data.urls,
              ];
              return updatedForms;
            });
          } else {
          }
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong!");
        }
      );
    }
  };

  console.log(forms, "forms data");

  const getAdditionalPartTypes = (id, index) => {
    props.getadditionalPartTypes(
      {},
      (res) => {
        setLoading(false);
        if (res.status) {
          setGetPart(res.data.data.data);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const handelAdditionalPartMitre = (
    event,
    index,
    indexTwo,
    type = null,
    label = null,
    mitreType
  ) => {
    event.preventDefault();

    const { name, value, files } = event.target;

    setForms((prevForms) => {
      const updatedForms = [...prevForms];

      const updatedMitre = updatedForms[index].mitreStyles[indexTwo] || {
        mitreValue: "",
        mitreStyleId: "",
        mitreLabel: "",
        mitreCount: "",
        mitreType: "",
      };

      if (type === "parts") {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const innerText = selectedOption.innerText;
        updatedMitre.mitreStyleId = value;
        updatedMitre.mitreValue = innerText;
        updatedMitre.mitreLabel = label;
        updatedMitre.mitreType = mitreType;
      } else if (type === "count") {
        updatedMitre[name] = value;
        // Object.assign(updatedMitre,{ [updatedMitre.mitreCount] : value});
      }
      if (type === "mitreCustom") {
        updatedMitre.mitreValue = value;
        updatedMitre.mitreLabel = label;
        updatedMitre.mitreType = mitreType;
        updatedMitre.mitreStyleId = "64d376d2693fa9614b0f928b";
      }
      // const finalMitres = mitreStylesArray.push(updatedMitre);

      updatedForms[index].mitreStyles[indexTwo] = updatedMitre;

      return updatedForms;
    });
  };

  const handelAdditionalElbows = (event, index, indexTwo) => {
    event.preventDefault();

    const { name, value, files } = event.target;
    if (name === "a") {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[index].elbowType.a = parseInt(value);
        return updatedForms;
      });
    } else if (name === "b") {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[index].elbowType.b = parseInt(value);
        return updatedForms;
      });
    } else if (name === "c") {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[index].elbowType.c = parseInt(value);
        return updatedForms;
      });
    }
  };

  const handelAdditionalHangers = (event, index, indexTwo, type = null) => {
    event.preventDefault();

    const { name, value } = event.target;

    setForms((prevForms) => {
      const updatedForms = [...prevForms];
      const updatedHanger = updatedForms[index].hangerTypes[indexTwo] || {
        hangerName: "",
        hangerId: "",
        hangerCount: "",
      };

      if (type === "parts") {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const innerText = selectedOption.innerText;
        updatedHanger[name] = value;
        updatedHanger.hangerName = innerText;
      } else if (type === "count") {
        updatedHanger[name] = value;
      }
      updatedForms[index].hangerTypes[indexTwo] = updatedHanger;
      return updatedForms;
    });
    const updatedValues = forms.map((form) => ({
      ...form,
      hangerTypes: form.hangerTypes.map((hanger) => ({ ...hanger })),
    }));
    setValues(updatedValues);
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which; // Get the key code
    const keyValue = String.fromCharCode(keyCode); // Convert key code to a character

    // Allow backspace and control keys
    if (keyCode === 8 || e.ctrlKey || e.altKey || e.metaKey) {
      return;
    }

    // Check if the key value is a number
    if (!/^\d$/.test(keyValue)) {
      e.preventDefault(); // Prevent non-numeric character entry
      toast.dismiss();
      toast.error("Please enter only numerical values.");
    }
  };

  const removeLineItem = (e, index) => {
    e.preventDefault();
    console.log(index, forms, "index of the form");
    const updatedForms = forms.filter((_, i) => i !== index);
    setForms(updatedForms);
  };

  const handleSingleDeleteImage = (formIndex, photoIndex) => {
    const updatedForms = [...forms];
    const currentPhotos = updatedForms[formIndex].photos;
    currentPhotos.splice(photoIndex, 1);
    updatedForms[formIndex].photos = currentPhotos;
    setForms(updatedForms);
  };

  const disableProductType =
    getCategory === "" ||
    category.some(
      (val) =>
        val._id === getCategory &&
        (val.slug === "failed_projects" ||
          val.slug === "other" ||
          val.slug === "gutter_cleaning")
    );

  const disabledProductsSub =
    getProduct === "" ||
    category.some(
      (val) =>
        val._id === getCategory &&
        (val.slug === "failed_projects" ||
          val.slug === "other" ||
          val.slug === "gutter_cleaning")
    );

  useEffect(() => {
    getAllCategories();
    // getAdditionalPartTypes(getCategory)
  }, []);

  return (
    <div>
      <>
        {/* <CCard style={{ boxShadow: 'none', border: 'none' }}> */}
        <form className="form_horizontal mt-3" onSubmit={(e) => addTemplate(e)}>
          {/* <div className='row'> */}
          {forms.map((item, index) => (
            <div className="innerCard">
              {console.log(item, "item in map")}
              <>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Product/Service
                    </label>
                    <select
                      className="form-select"
                      name="categoryId"
                      value={item.categoryId}
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleInputChange(e, index, "categoryId")
                      }
                    >
                      <option selected disabled value="">
                        Choose product category
                      </option>

                      {category?.map((val, index) => (
                        <>
                          <option value={val._id} key={index}>
                            {val.name}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-6 mb-3 text-end align-self-center">
                    <button
                      onClick={(e) => removeLineItem(e, index)}
                      className="border-0"
                      style={{ background: "none" }}
                    >
                      <img src={crossIcon} alt="" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group mb-3">
                      <label
                        for="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        Description
                      </label>
                      <textarea
                        maxLength="2000"
                        className="form-control customFormCtrl"
                        value={item.description}
                        name="description"
                        id="exampleFormControlTextarea1"
                        placeholder="Enter the product details"
                        rows="5"
                        onChange={(e) => handleInputChange(e, index)}
                      >
                        {item.description}
                      </textarea>
                      <p className="text-end">
                        {item.description.length} / 2000
                      </p>
                    </div>
                  </div>
                </div>
                {category.some(
                  (val) =>
                    val._id === getCategory &&
                    (forms[index].categorySlug === "failed_projects" ||
                      forms[index].categorySlug === "other" ||
                      forms[index].categorySlug === "gutter_cleaning")
                ) ? (
                  ""
                ) : (
                  <>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            {" "}
                            Type
                          </label>
                          <select
                            className="form-select"
                            value={item.subCategoryId}
                            name="subCategoryId"
                            aria-label="Default select example"
                            disabled={disableProductType}
                            onChange={(e) =>
                              handleInputChange(e, index, "subCategoryId")
                            }
                          >
                            <option value="" disabled selected>
                              Choose product type
                            </option>
                            {productType?.map((val, index) => (
                              <>
                                <option
                                  value={val._id}
                                  key={index}
                                  selected={item.name === val.name}
                                >
                                  {val.name}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Color/Finish
                          </label>
                          <select
                            className="form-select"
                            value={item.colorId}
                            name="colorId"
                            aria-label="Default select example"
                            disabled={disableProductType}
                            onChange={(e) =>
                              handleInputChange(e, index, "colorId")
                            }
                          >
                            <option value="" disabled selected>
                              Choose product color
                            </option>
                            {color?.map((val, index) => (
                              <>
                                <option
                                  value={val._id}
                                  key={index}
                                  selected={item.name === val.name}
                                >
                                  {val.name}
                                </option>
                              </>
                            ))}
                          </select>
                          {/* {formErrors[index].colorName && (
                                                                            <div className="error">{formErrors[index].colorName}</div>
                                                                        )} */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Material
                          </label>
                          <select
                            className="form-select"
                            value={item.materialId}
                            name="materialId"
                            aria-label="Default select example"
                            disabled={disableProductType}
                            onChange={(e) =>
                              handleInputChange(e, index, "materialId")
                            }
                          >
                            <option value="" disabled selected>
                              Choose product material
                            </option>
                            {material?.map((val, index) => (
                              <>
                                <option
                                  value={val._id}
                                  key={index}
                                  selected={item.name === val.name}
                                >
                                  {val.name}
                                </option>
                              </>
                            ))}
                          </select>
                          {/* {formErrors[index].materialName && (
                                                                            <div className="error">{formErrors[index].materialName}</div>
                                                                        )} */}
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Sizes
                          </label>
                          <select
                            className="form-select"
                            value={item.sizeId}
                            name="sizeId"
                            aria-label="Default select example"
                            disabled={disabledProductsSub}
                            onChange={(e) => handleInputChange(e, index)}
                          >
                            <option value="" selected disabled>
                              Choose product sizes
                            </option>
                            {sizes?.map((val, index) => (
                              <>
                                <option
                                  value={val._id}
                                  key={index}
                                  selected={item.name === val.name}
                                >
                                  {val.name}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {item.showParts ? (
                  <>
                    {getPart.map((part, indexTwo) => (
                      <>
                        {part.slug == "mitre" &&
                          forms[index].categoryId === part.categoryId ? (
                          <div className="Mitres">
                            <div className="row">
                              <h4>{part.name}</h4>
                              <>
                                <div className="col-lg-6 mb-3">
                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      90° Inside Mitres Type
                                    </label>

                                    <select
                                      className="form-select"
                                      name="mitreStyleId"
                                      aria-label="Default select example"
                                      onChange={(e) =>
                                        handelAdditionalPartMitre(
                                          e,
                                          index,
                                          0,
                                          "parts",
                                          "90° Inside Mitres",
                                          "list"
                                        )
                                      }
                                    >
                                      <option selected value="">
                                        Choose product additional parts
                                      </option>
                                      {part?.additional_parts?.map(
                                        (val, index) => (
                                          <>
                                            <option value={val._id} key={index}>
                                              {val.name}
                                            </option>
                                          </>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleFormControlInput1"
                                      className="form-label"
                                    >
                                      90° Inside Mitres Count (6 Digits)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control customFormCtrl"
                                      name="mitreCount"
                                      id="exampleFormControlInput1"
                                      placeholder="12"
                                      maxLength="6"
                                      onKeyPress={(e) => handleKeyPress(e)}
                                      onChange={(e) =>
                                        handelAdditionalPartMitre(
                                          e,
                                          index,
                                          0,
                                          "count",
                                          "90° Inside Mitres",
                                          "list"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    90° Outside Mitres Type
                                  </label>
                                  <select
                                    className="form-select"
                                    name="mitreStyleId"
                                    aria-label="Default select example"
                                    onChange={(e) =>
                                      handelAdditionalPartMitre(
                                        e,
                                        index,
                                        1,
                                        "parts",
                                        "90° Outside Mitres",
                                        "list"
                                      )
                                    }
                                  >
                                    <option selected value="">
                                      Choose product additional parts
                                    </option>
                                    {part?.additional_parts?.map(
                                      (val, index) => (
                                        <>
                                          <option value={val._id} key={index}>
                                            {val.name}
                                          </option>
                                        </>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    90° Outside Mitres Count (6 Digits)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control customFormCtrl"
                                    name="mitreCount"
                                    id="exampleFormControlInput1"
                                    placeholder="12"
                                    maxLength="6"
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    onChange={(e) =>
                                      handelAdditionalPartMitre(
                                        e,
                                        index,
                                        1,
                                        "count",
                                        "90° Outside Mitres",
                                        "list"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Bay Inside Mitres Type
                                  </label>
                                  <select
                                    className="form-select"
                                    name="mitreStyleId"
                                    aria-label="Default select example"
                                    onChange={(e) =>
                                      handelAdditionalPartMitre(
                                        e,
                                        index,
                                        2,
                                        "parts",
                                        "Bay Inside Mitres",
                                        "list"
                                      )
                                    }
                                  >
                                    <option selected value="">
                                      Choose product additional parts
                                    </option>
                                    {part?.additional_parts?.map(
                                      (val, index) => (
                                        <>
                                          <option value={val._id} key={index}>
                                            {val.name}
                                          </option>
                                        </>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Bay Inside Mitres Count (6 Digits)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control customFormCtrl"
                                    name="mitreCount"
                                    id="exampleFormControlInput1"
                                    maxLength="6"
                                    placeholder="12"
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    onChange={(e) =>
                                      handelAdditionalPartMitre(
                                        e,
                                        index,
                                        2,
                                        "count",
                                        "Bay Inside Mitres",
                                        "list"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Bay Outside Mitres Type
                                  </label>

                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    onChange={(e) =>
                                      handelAdditionalPartMitre(
                                        e,
                                        index,
                                        3,
                                        "parts",
                                        "Bay Outside Mitres",
                                        "list"
                                      )
                                    }
                                  >
                                    <option selected value="">
                                      Choose product additional parts
                                    </option>
                                    {part?.additional_parts?.map(
                                      (val, index) => (
                                        <>
                                          <option value={val._id} key={index}>
                                            {val.name}
                                          </option>
                                        </>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Bay Outside Mitres Count (6 Digits)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control customFormCtrl"
                                    name="mitreCount"
                                    maxLength="6"
                                    id="exampleFormControlInput1"
                                    placeholder="12"
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    onChange={(e) =>
                                      handelAdditionalPartMitre(
                                        e,
                                        index,
                                        3,
                                        "count",
                                        "Bay Outside Mitres",
                                        "list"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Custom Mitres Type
                                  </label>
                                  <input
                                    className="form-control customFormCtrl"
                                    type="text"
                                    name="mitreTypeCustom"
                                    // onKeyPress={(e) => handleKeyPress(e)}
                                    placeholder="enter custom name"
                                    onChange={(e) =>
                                      handelAdditionalPartMitre(
                                        e,
                                        index,
                                        4,
                                        "mitreCustom",
                                        "Custom Mitres",
                                        "custom"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Custom Mitres Count (6 Digits)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control customFormCtrl"
                                    maxLength="6"
                                    name="mitreCount"
                                    id="exampleFormControlInput1"
                                    placeholder="12"
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    onChange={(e) =>
                                      handelAdditionalPartMitre(
                                        e,
                                        index,
                                        4,
                                        "count",
                                        "Custom Mitres"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : part.slug == "hangers" &&
                          forms[index].categoryId === part.categoryId ? (
                          <div className="Hanger">
                            <div className="row">
                              <div className="d-flex justify-content-between">
                                <h4>{part.name}</h4>
                                {forms[index]?.hangerTypes?.length < 6 ? (
                                  <button
                                    type="btn "
                                    onClick={(e) => handleAddMitre(e, index)}
                                    className="plusIcon"
                                  >
                                    <img src={plusIcon} alt="" />
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                              {forms[index]?.hangerTypes?.map(
                                (item, indexTwo) => (
                                  <>
                                    {indexTwo > 0 ? (
                                      <button
                                        type="btn"
                                        onClick={() =>
                                          handleRemoveMitre(index, indexTwo)
                                        }
                                        className="plusIcon removeHanger"
                                      >
                                        <img src={crossIcon} alt="" />
                                      </button>
                                    ) : (
                                      ""
                                    )}

                                    <div
                                      className="col-lg-6 mb-3"
                                      key={indexTwo}
                                    >
                                      <div className="form-group mb-3">
                                        <label
                                          for="exampleFormControlInput1"
                                          className="form-label"
                                        >
                                          {" "}
                                          {indexTwo > 0
                                            ? `Hanagr Type ${indexTwo}`
                                            : "Hangar Type"}
                                        </label>
                                        <select
                                          className="form-select"
                                          aria-label="Default select example"
                                          name="hangerId"
                                          onChange={(e) =>
                                            handelAdditionalHangers(
                                              e,
                                              index,
                                              indexTwo,
                                              "parts"
                                            )
                                          }
                                        >
                                          <option selected value="">
                                            Choose product additional parts
                                          </option>
                                          {part?.additional_parts?.map(
                                            (val, index) => (
                                              <>
                                                <option
                                                  value={val._id}
                                                  key={index}
                                                >
                                                  {val.name}
                                                </option>
                                              </>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                      <div className="form-group mb-3">
                                        <label
                                          for="exampleFormControlInput1"
                                          className="form-label"
                                        >
                                          Hangar Count (6 Digits)
                                        </label>
                                        <input
                                          maxLength="6"
                                          type="text"
                                          className="form-control customFormCtrl"
                                          id="exampleFormControlInput1"
                                          name="hangerCount"
                                          placeholder="Enter hanger count"
                                          onKeyPress={(e) => handleKeyPress(e)}
                                          onChange={(e) =>
                                            handelAdditionalHangers(
                                              e,
                                              index,
                                              indexTwo,
                                              "count"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        ) : part.slug === "elbow" &&
                          item.categoryId === part.categoryId ? (
                          <div className="elbow">
                            <div className="row">
                              <h4>{part.name}</h4>
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Elbow A
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control customFormCtrl"
                                    name="a"
                                    id="exampleFormControlInput1"
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    placeholder="Enter A elbows"
                                    onChange={(e) =>
                                      handelAdditionalElbows(e, index)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Elbow B
                                  </label>
                                  <input
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    type="text"
                                    className="form-control customFormCtrl"
                                    name="b"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter B elbows"
                                    onChange={(e) =>
                                      handelAdditionalElbows(e, index)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-3">
                                <div className="form-group mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Elbow C
                                  </label>
                                  <input
                                    type="text"
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    className="form-control customFormCtrl"
                                    name="c"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter C elbows"
                                    onChange={(e) =>
                                      handelAdditionalElbows(e, index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}

                <hr />

                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <div className="form-group">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Unit Price
                      </label>
                      <NumericFormat
                        name="unitPrice"
                        className="form-control customFormCtrl"
                        id="exampleFormControlInput1"
                        placeholder="Price"
                        onChange={(e) =>
                          handleInputChange(e, index, "unitPrice")
                        }
                        decimalScale={2}
                        value={item.unitPrice}
                        // thousandSeparator={','}
                        // decimalSeparator={','}
                        prefix={"$"}
                      />
                    </div>
                  </div>
                  {category.some(
                    (val) =>
                      val._id === forms[index].categoryId &&
                      (val.slug === "failed_projects" ||
                        val.slug === "other" ||
                        val.slug === "gutter_cleaning")
                  ) ? (
                    ""
                  ) : category.some(
                    (val) =>
                      val._id === forms[index].categoryId &&
                      (val.slug === "gutters" ||
                        val.slug === "downspouts" ||
                        val.slug === "gutter_covers")
                  ) ? (
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Quantity
                        </label>
                        <input
                          value={item.quantity}
                          type="text"
                          className="form-control customFormCtrl"
                          onKeyPress={(e) => handleKeyPress(e)}
                          name="quantity"
                          onChange={(e) => handleInputChange(e, index)}
                          id="exampleFormControlInput1"
                          placeholder="Quantity"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-lg-12 mb-3">
                  <div className="d-flex">
                    <h1 className="uploadHeading mb-2">Upload Photos</h1>
                    <span className="subHeadingGray ms-2">(Upto 8 images)</span>
                  </div>
                  <div className="edit_profile mb-3 d-flex ">
                    <>
                      {forms[index].photos?.map((val, indexImg) => (
                        <>
                          <div className="uploadedData addTempData me-2">
                            <div className="customCheck ">
                              <span
                                className="xIcon"
                                onClick={(e) =>
                                  handleSingleDeleteImage(index, indexImg)
                                }
                              >
                                <i class="fa-solid fa-x"></i>
                              </span>
                            </div>

                            <img
                              src={val}
                              alt="images uploaded"
                              value={item.photos}
                              className=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          </div>
                        </>
                      ))}
                      <label
                        htmlFor={`file-upload${index}`}
                        className="custom-file-upload"
                      >
                        <input
                          id={`file-upload${index}`}
                          type="file"
                          multiple
                          name="profile_image"
                          className="d-none"
                          onChange={(e) => {
                            onUploadProductImage(e, index, "thumbnail");
                          }}
                          accept="image/*"
                        />
                        <img
                          src={addSymbol}
                          alt="upload_img"
                          className="img-fluid edit_iconmodal"
                        />
                      </label>
                    </>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="totalPrice">
                      <p>Total </p>
                      <span>
                        $
                        {forms[index].totalPrice
                          ? forms[index].totalPrice
                          : "0.00"}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            </div>
          ))}
          <div className="col-lg-12 mb-3 mt-5">
            <button onClick={(e) => handleAddForm(e)} className="addLineBtn">
              <img src={plusIcon} alt="" className="img-fluid me-2" />
              Add Line Item
            </button>
          </div>

          {/* </div> */}
          <div className="row mt-4">
            <div className="col-12 text-center">
              <button
                className="btn button"
                type="submit"
                style={{ padding: "13px 40px" }}
              >
                Save Template
              </button>
            </div>
          </div>
        </form>
        {/* </CCard> */}
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: bindActionCreators(getCategories, dispatch),
  getProducts: bindActionCreators(getProducts, dispatch),
  getSizes: bindActionCreators(getSizes, dispatch),
  getColor: bindActionCreators(getColor, dispatch),
  getMaterial: bindActionCreators(getMaterial, dispatch),
  uploadProductImage: bindActionCreators(uploadProductImage, dispatch),
  addProduct: bindActionCreators(addProduct, dispatch),
  getadditionalPartTypes: bindActionCreators(getadditionalPartTypes, dispatch),
  addTemplate: bindActionCreators(addTemplate, dispatch),
  updateTemplate: bindActionCreators(updateTemplate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewLineItem);
