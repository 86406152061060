import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from "redux";
import { createSupplier, editSupplier, getOneSupplier, uploadSupplierImage } from '../../actions/directoryManagement';
import { TagsInput } from "react-tag-input-component";
import { useEffect } from 'react';
import { getAllCountry, getState } from '../../actions/user';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const AddEditDirectory = (props) => {
    const { isEdit, Details, onCloseModal } = props

    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [countryList, setCountryList] = useState([])
    const [country, setCountry] = useState('us')
    const [stateList, setStateList] = useState([])
    const [data, setData] = useState({
        "supplierName": "",
        "salesManager": "",
        "contactEmail": "",
        "phone": "",
        "address": {
            "addressLine": "",
            "city": "",
            "zipcode": "",
            "state": "",
            "country": "",
            "latitude": '',
            "longitude": ''
        },
        "hashtags": [],
        "logo": "",
        "salesManagerPhoto": "",
        "advertisementImage": ""
    })

    const getSupplierDetails = () => {
        let params = {
            token: props.auth.token,
            id: Details._id,
        };
        setDataLoading(true)
        props.getOneSupplier(
            params,
            (res) => {
                setDataLoading(false);
                if (res.status) {
                    setData(res.data.data)
                    // getStateList(res.data.data.address.country)
                } else {
                }
            },
            (err) => {
                setDataLoading(false);
                toast.error("Something went wrong!");
            }
        );
    };

    const getCountryList = () => {
        let params = {}
        props.getAllCountry(params, res => {
            if (res.status) {
                setCountryList(res.data.data)
            }
            else {
            }
        })
    }

    const getStateList = () => {
        let params = {
            id: countryList.find(val => val.name === data.address.country)?.country_Id
        }
        props.getState(params, res => {
            if (res.status) {
                setStateList(res.data.data)
            }
            else {
            }
        })
    }

    useEffect(() => {
        getCountryList()
        if (isEdit) {
            getSupplierDetails()
        }
    }, [isEdit])

    useEffect(() => {
        if (data.address.country) {
            getStateList()
        }
    }, [data.address.country])


    const onSubmit = () => {
        if (!data.supplierName) {
            toast.error('Please enter supplier name');
            return
        }
        if (!data.contactEmail) {
            toast.error('Please enter email');
            return
        }
        if (!data.phone) {
            toast.error('Please enter Phone no');
            return
        }
        if (!data.address.addressLine) {
            toast.error('Please enter address');
            return
        }
        if (!data.address.city) {
            toast.error('Please enter city');
            return
        }
        if (!data.address.state) {
            toast.error('Please enter state');
            return
        }
        if (!data.address.country) {
            toast.error('Please enter country');
            return
        }
        if (!data.address.zipcode) {
            toast.error('Please enter zipcode');
            return
        }
        if (!data.address.latitude) {
            toast.error('Please enter latitude');
            return
        }
        if (!data.address.longitude) {
            toast.error('Please enter longitude');
            return
        }
        if (data.hashtags.length <= 0) {
            toast.error('Please enter hash tags');
            return
        }
        if (!data.logo) {
            toast.error('Please add a logo');
            return
        }
        if (!data.advertisementImage) {
            toast.error('Please add an advertisement image');
            return
        }
        let params = data
        setLoading(true)
        if (isEdit) {
            delete params.createdAt
            delete params.createdBy
            delete params.deletedAt
            delete params.updatedAt
            delete params.__v
            delete params._id
            delete params.promoteDuration
            delete params.promoteFlag
            delete params.address.location
            delete params.businessName
            props.editSupplier({ id: Details._id, ...params }, res => {
                setLoading(false);
                if (res.status) {
                    toast.success('Updated successfully!')
                    onCloseModal()
                } else {
                    toast.error(res.message);
                }
            }, (err) => {
                setLoading(false);
                toast.error(err.message);
            })
        } else {
            props.createSupplier(params, res => {
                setLoading(false);
                if (res.status) {
                    toast.success(`Added successfully!`)
                    onCloseModal()
                } else {
                    toast.error(res.message);
                }
            }, (err) => {
                setLoading(false);
                toast.error(err.message);
            })
        }
    };

    const onChangeImage = (e) => {
        const imageFile = e.target.files[0];
        let params = { image: imageFile }

        props.uploadImage(params, res => {
            if (res.status) {
                setData({ ...data, [e.target.name]: res.data.data.url })
            }
        }, err => {
            toast.error("Something went wrong!");
        })

    };

    const onChangeInput = (e) => {
        const { name, value } = e.target;

        if (name.includes('address.')) {
            const nestedPropertyName = name.split('address.')[1];

            setData({
                ...data,
                address: {
                    ...data.address,
                    [nestedPropertyName]: nestedPropertyName == 'latitude' || nestedPropertyName == 'longitude' ? parseFloat(value) : value
                }
            });
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const handleCountryChange = (e) => {
        getStateList(e.target.value)
    }

    const handlePaste = (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        const newTags = pastedData.split(',').map((tag) => tag.trim());
        setData({ ...data, hashtags: [...data.hashtags, ...newTags] });
    };

    const handleOnChange = (newTags) => {
        const validTags = newTags
            .filter((tag) => tag.trim() !== '') // Filter out empty tags
            .map((tag) => tag.trim()); // Trim whitespace from tags

        const lastTag = validTags[validTags.length - 1];

        if (lastTag && lastTag.includes(',')) {
            const additionalTags = lastTag.split(',').map((tag) => tag.trim());
            validTags.pop(); // Remove the incomplete tag
            setData({ ...data, hashtags: [...validTags, ...additionalTags] });
        } else {
            setData({ ...data, hashtags: validTags });
        }
    };

    return (
        <>
            <div className='addSuplierModal'>
                <div className='row p-3'>
                    <div className="form-group col-md-12">
                        <label className="label_text" htmlFor="supplierName">Supplier Name*</label>
                        <input
                            name="supplierName"
                            placeholder="Enter Supplier Name"
                            id="supplierName"
                            value={data.supplierName}
                            autoComplete="off"
                            className="form-control customFormCtrl"
                            onChange={(e) => { onChangeInput(e) }}
                        />
                    </div>

                    <div className="form-group col-md-12">
                        <label className="label_text" htmlFor="salesManager">Sales Manager</label>
                        <input
                            name="salesManager"
                            placeholder="Enter Sales Manager Name"
                            id="salesManager"
                            value={data.salesManager}
                            autoComplete="off"
                            className="form-control customFormCtrl"
                            onChange={(e) => { onChangeInput(e) }}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <label className="label_text" htmlFor="contactEmail">Contact Email</label>
                        <input
                            name="contactEmail"
                            placeholder="Enter Supplier Email"
                            id="contactEmail"
                            value={data.contactEmail}
                            autoComplete="off"
                            type="email"
                            className="form-control customFormCtrl"
                            onChange={(e) => { onChangeInput(e) }}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <label className="label_text" htmlFor="phone">Phone</label>
                        <PhoneInput
                            country={country}
                            value={data.phone}
                            onChange={(value, country, e, formattedValue) => { setCountry(country.countryCode); setData({ ...data, phone: formattedValue }) }}
                            placeholder="Enter Phone No."
                            inputClass="form-control customFormCtrl"
                            inputProps={{
                                name: 'phone',
                                autoComplete: "off"
                            }}
                            inputStyle={{ width: '100%' }}
                            onlyCountries={['us', 'ca', 'au', 'nz', 'gb']}
                        />
                    </div>

                    <div className="form-group col-md-12">
                        <label className="label_text" htmlFor="address.addressLine">Address*</label>
                        <input
                            name="address.addressLine"
                            placeholder="Enter Address"
                            id="address.addressLine"
                            value={data.address.addressLine}
                            autoComplete="off"
                            className="form-control customFormCtrl"
                            onChange={(e) => { onChangeInput(e) }}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <input
                            name="address.city"
                            placeholder="Enter City"
                            id="address.city"
                            value={data.address.city}
                            autoComplete="off"
                            className="form-control customFormCtrl"
                            onChange={(e) => { onChangeInput(e) }}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <select className="form-control" style={{ width: "100%" }} value={data.address.state} name="address.state" id="address.state" onChange={(e) => { onChangeInput(e) }}>
                            <option value={""}>Select State</option>
                            {stateList?.length > 0 ? stateList?.map((value, index) => {
                                return (
                                    <option value={value?.name} key={index}>{value?.name}</option>
                                )
                            }) : <></>}
                        </select>
                    </div>

                    <div className="form-group col-md-6">
                        <select className="form-control" style={{ width: "100%" }} value={data.address.country} name="address.country" id="address.country" onChange={(e) => { onChangeInput(e); handleCountryChange(e) }}>
                            <option value={""}>Select Country</option>
                            {countryList?.length > 0 ? countryList?.map((value, index) => {
                                return (
                                    <option value={value?.name} key={index}>{value?.name}</option>
                                )
                            }) : <></>}
                        </select>
                    </div>

                    <div className="form-group col-md-6">
                        <input
                            name="address.zipcode"
                            placeholder="Enter Zipcode"
                            id="address.zipcode"
                            value={data.address.zipcode}
                            autoComplete="off"
                            maxLength={8}
                            className="form-control customFormCtrl"
                            onChange={(e) => { onChangeInput(e) }}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <input
                            name="address.latitude"
                            placeholder="Enter Latitude"
                            id="address.latitude"
                            value={data.address.latitude}
                            autoComplete="off"
                            type='number'
                            className="form-control customFormCtrl"
                            onChange={(e) => { onChangeInput(e) }}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <input
                            name="address.longitude"
                            placeholder="Enter Longitude"
                            id="address.longitude"
                            value={data.address.longitude}
                            autoComplete="off"
                            type='number'
                            className="form-control customFormCtrl"
                            onChange={(e) => { onChangeInput(e) }}
                        />
                    </div>

                    <div className="form-group col-md-12">
                        <label className="label_text" htmlFor="address.addressLine">Add keyword / Hashtag *</label>
                        <TagsInput
                            value={data.hashtags}
                            onChange={(newTags) => handleOnChange(newTags)}
                            name="hashtags"
                            separators={['Enter', ',']}
                            placeHolder="Type or Paste here"
                            onPaste={handlePaste}

                        />
                    </div>

                    <div className="row uploadImages">
                        <div className="form-group col-md-4">
                            <div class="custom-file-upload">
                                <label className="label_text" htmlFor="logo ">Logo*</label>
                                <p className='imageSize'>Image size 200 * 200</p>
                                {data.logo ? <><img className="LogoAddSuplier" src={data.logo} /><p onClick={() => setData({ ...data, logo: '' })} className='CrossBtn'>X</p></> :
                                    <>
                                        <input type="file" id="logo" accept="image/*" class="input-hidden" onChange={(e) => onChangeImage(e)} placeholder="Logo" name="logo" />
                                        <label for="logo" class="custom-label">+</label>
                                    </>}
                                {/* <p id="fileName" class="file-name">No file chosen</p> */}
                            </div>
                        </div>
                        <div className="form-group col-md-4">
                            <div class="custom-file-upload">
                                <label className="label_text" htmlFor="salesManagerPhoto ">Sales Manager Photo</label>
                                <p className='imageSize'>Image size 1080 * 1080</p>
                                {data.salesManagerPhoto ? <><img className="LogoAddSuplier" src={data.salesManagerPhoto} /><p onClick={() => setData({ ...data, salesManagerPhoto: '' })} className='CrossBtn'>X</p></> :
                                    <>
                                        <input type="file" id="salesManagerPhoto" accept="image/*" class="input-hidden" onChange={(e) => onChangeImage(e)} placeholder="salesManagerPhoto" name="salesManagerPhoto" />
                                        <label for="salesManagerPhoto" class="custom-label">+</label>
                                    </>}
                                {/* <p className='CrossBtn'>X</p> */}
                                {/* <p id="fileName" class="file-name">No file chosen</p> */}
                            </div>
                        </div>
                        <div className="form-group col-md-4">
                            <div class="custom-file-upload">
                                <label className="label_text" htmlFor="advertisementImage">Advertisement Image*</label>
                                <p className='imageSize'>Image size 1080 * 1080</p>
                                {data.advertisementImage ? <><img className="LogoAddSuplier" src={data.advertisementImage} /><p onClick={() => setData({ ...data, advertisementImage: '' })} className='CrossBtn'>X</p></> :
                                    <>
                                        <input type="file" id="advertisementImage" accept="image/*" class="input-hidden" onChange={(e) => onChangeImage(e)} placeholder="advertisementImage" name="advertisementImage" />
                                        <label for="advertisementImage" class="custom-label">+</label>
                                    </>}
                                {/* <p id="fileName" class="file-name">No file chosen</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <div className='row text-center'>
                    <div className='col-md-12'>
                        <button className='btn button px-5 py-2' onClick={onSubmit} type='submit'>{loading ? '...' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    createSupplier: bindActionCreators(createSupplier, dispatch),
    editSupplier: bindActionCreators(editSupplier, dispatch),
    uploadImage: bindActionCreators(uploadSupplierImage, dispatch),
    getOneSupplier: bindActionCreators(getOneSupplier, dispatch),
    getAllCountry: bindActionCreators(getAllCountry, dispatch),
    getState: bindActionCreators(getState, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditDirectory);